/*******************************
          Advertisement
*******************************/

@margin: 1em 0em;
@overflow: hidden;

@testBackground: @lightBlack;
@testColor: @white;
@testFontWeight: @bold;
@testText: 'Ad';
@testFontSize: @relativeMedium;
@testMobileFontSize: @relativeTiny;
