/*******************************
            Flag
*******************************/

/*-------------------
       Element
--------------------*/

@spritePath: "@{imagePath}/flags.png";
@width: 16px;
@height: 11px;
@verticalAlign: baseline;
@margin: 0.5em;