/*******************************
            Reveal
*******************************/

@transitionDelay: 0.1s;
@transitionDuration: 0.5s;
@transitionEasing: cubic-bezier(0.175, 0.885, 0.320, 1);
@transition: all @transitionDuration @defaultEasing @transitionDelay;

@bottomZIndex: 2;
@topZIndex: 3;
@activeZIndex: 4;
@overlayZIndex: 5;

/* Types */
@rotateDegrees: 110deg;
@moveTransition: transform @transitionDuration @transitionEasing @transitionDelay;
@slideTransition: transform @transitionDuration @defaultEasing @transitionDelay;
